<template>
  <b-button-group>
    <b-button v-for="(text, key) in Kundentypen" :key="key" :pressed="selected === key"
              variant="fc-gray-light" v-on:click="$emit('change', key)">{{ text }}
    </b-button>
  </b-button-group>
</template>

<script>
  import Vue from 'vue'
  import {ButtonGroupPlugin} from 'bootstrap-vue'
  import {Kundentypen} from '../constants'

  Vue.use(ButtonGroupPlugin)
  export default {
    name: 'KundentypSchalter',
    model: {
      prop: 'selected',
      event: 'change'
    },
    props: {
      selected: String
    },
    data() {
      return {
        Kundentypen
      }
    }
  }
</script>

<style scoped>

</style>
